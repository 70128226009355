import React, { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import { approveVault, depositVault } from '../../../blockchain/vaults';

import { getLpAddress, trunc } from '../../../utils/commons';

import { ToastContext } from '../../../context/toast';
import { WalletContext } from '../../../context/wallet';

const VaultDepositSection = ({ vaultData, started }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');

  const userBalance = (mul = 1) => {
    const decimals = vaultData.isTokenOnly ? vaultData.tokenDecimals : process.env.REACT_APP_DECIMALS;

    return trunc(vaultData.userBalance, mul, decimals);
  }

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const canDeposit = () => {
    const value = new BigNumber(val);
    return !value.isNaN() && value.gt(0) && value.lte(userBalance());
  }

  const renderUnlockApproveOrDeposit = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    if (new BigNumber(vaultData.userAllowance).eq(0)) {
      return (
        <button
          disabled={ pendingTx || !started }
          className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
          onClick={ handleApprove }
        >
          Approve
        </button>
      );
    }

    return (
      <button
          onClick={ handleDeposit }
          // disabled={ pendingTx || !started || !canDeposit() }
          disabled
          className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
        >
        Deposit
      </button>
    );
  }

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveVault(vaultData.isTokenOnly ? vaultData.token : getLpAddress(vaultData.token, vaultData.quoteToken));
      await tx.wait();
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Approve succeeded`, 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Approve failed`, 'is-danger');
    }

    setPendingTx(false);
  }

  const handleDeposit = async () => {
    const decimals = vaultData.isTokenOnly ? vaultData.tokenDecimals : process.env.REACT_APP_DECIMALS;
    const amount = new BigNumber(val).times(new BigNumber(10).pow(decimals)).toFixed();

    let tx;
    try {
      setPendingTx(true);
      tx = await depositVault(vaultData.pid, amount);
      await tx.wait();
      setVal('');
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Deposit succeeded`, 'is-success');
      setPendingTx(false);
    } catch (error) {
      tx = { error: error.data ? error.data.message : error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast(`${vaultData.isTokenOnly ? '' : 'LP '}Token Deposit failed`, 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <>
      <div className="buttons is-right mb-0">
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(userBalance(0.25).toFixed()) }>
          25%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(userBalance(0.5).toFixed()) }>
          50%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(userBalance(0.75).toFixed()) }>
          75%
        </button>
        <button className="button is-success is-small is-outlined" onClick={ () => setValue(userBalance().toFixed()) }>
          100%
        </button>
      </div>
      <div className="is-flex is-justify-content-end">
        <button className="button is-text" onClick={ () => setValue(userBalance().toFixed()) }>
          { userBalance().toFormat() } available
        </button>
      </div>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
        </div>
        <div className="control">
          { renderUnlockApproveOrDeposit() }
        </div>
      </div>
    </>
  );
}

export default VaultDepositSection;
