import React, { useState, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import  { buyPreBanksy } from '../../blockchain/preSale';
import { trunc } from '../../utils/commons';

import { ToastContext } from '../../context/toast';

const PreBanksyBuyModal = ({ preSaleState, isModalActive, onModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');

  const max = useMemo(() => {
    return new BigNumber(preSaleState.preBanksyPerAccountMaxTotal)
      .minus(preSaleState.userPreBanksyTotally)
      .div(new BigNumber(10).pow(preSaleState.preBanksyDecimals))
      .times(preSaleState.salePrice)
      .times(new BigNumber(10).pow(preSaleState.tokenDecimals));
  }, [
    preSaleState.preBanksyPerAccountMaxTotal,
    preSaleState.userPreBanksyTotally,
    preSaleState.preBanksyDecimals,
    preSaleState.salePrice,
    preSaleState.tokenDecimals,
  ]);

  const remaining = useMemo(() => {
    return new BigNumber(preSaleState.preBanksyRemaining)
      .div(new BigNumber(10).pow(preSaleState.tokenDecimals));
  }, [preSaleState.preBanksyRemaining, preSaleState.tokenDecimals]);

  const userMax = (mul = 1) => {
    return trunc(Math.min(max, remaining, preSaleState.userTokenBalance), mul, preSaleState.tokenDecimals);
  }

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const canBuy = () => {
    const value = new BigNumber(val);
    return !value.isNaN() && value.gt(0) && value.lte(userMax());
  }

  const handleBuy = async () => {
    const decimals = preSaleState.tokenDecimals;
    const amount = new BigNumber(val).times(new BigNumber(10).pow(decimals)).toFixed();

    let tx;
    try {
      setPendingTx(true);
      tx = await buyPreBanksy(amount);
      await tx.wait();
      addToast('PreBanksy purchase succeeded', 'is-success');
      setPendingTx(false);
      setVal('0');
      onModalClose();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('PreBanksy purchase failed', 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Buy PRE-BANKSY</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-right mb-0">
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(userMax(0.25).toFixed()) }>
              25%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(userMax(0.5).toFixed()) }>
              50%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(userMax(0.75).toFixed()) }>
              75%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setValue(userMax().toFixed()) }>
              100%
            </button>
            <button className="button is-text" onClick={ () => setValue(userMax().toFixed()) }>
              { userMax().toFormat(6) } max
            </button>
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
            </div>
            <div className="control">
              <button type="button" className="button is-static">
                USDC.e
              </button>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                Your USDC.e balance:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <div className="has-text-right">
                  <h6 className="has-text-weight-semibold is-size-6">${ new BigNumber(preSaleState.userTokenBalance).div(new BigNumber(10).pow(preSaleState.tokenDecimals)).toFormat(2) }</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                You can buy Up to:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <div className="has-text-right">
                  <h6 className="has-text-weight-semibold is-size-6">{ new BigNumber(preSaleState.preBanksyPerAccountMaxTotal).minus(preSaleState.userPreBanksyTotally).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).toFormat(2) }</h6>
                  <h6 className="has-text-weight-semibold is-size-6">~${ new BigNumber(preSaleState.preBanksyPerAccountMaxTotal).minus(preSaleState.userPreBanksyTotally).div(new BigNumber(10).pow(preSaleState.preBanksyDecimals)).times(preSaleState.salePrice).toFormat(2) }</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                Price per PRE-BANKSY:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <h6 className="has-text-weight-semibold is-size-6">${ new BigNumber(preSaleState.salePrice).toFormat(2) }</h6>
              </div>
            </div>
          </div>
          <div className="level is-mobile mb-1">
            <div className="level-left">
              <div className="level-item">
                PRE-BANKSY you will buy:
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <h5 className="has-text-weight-semibold has-text-primary is-size-5">{ new BigNumber(val || 0).div(preSaleState.salePrice).toFormat(2) }</h5>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            onClick={ handleBuy }
            disabled={ pendingTx || !canBuy() }
            className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
          >
            Confirm
          </button>
          <button
            disabled={ pendingTx }
            className="button"
            onClick={ onModalClose }
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}

export default PreBanksyBuyModal;
