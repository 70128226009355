import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { faExternalLinkAlt, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PoolCardFooterSection = ({ poolData }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const toggleDetails = (evt) => {
    evt.preventDefault();
    setIsDetailsOpen(!isDetailsOpen);
  }

  const linkToViewOn = () => {
    return `${process.env.REACT_APP_EXPLORER}/address/${poolData.address}`;
  }

  const totalStaked = () => {
    return new BigNumber(poolData.totalStaked)
      .div(new BigNumber(10).pow(poolData.stakedTokenDecimals))
      .toFormat(2)
  }

  const totalStakedStable = () => {
    return new BigNumber(poolData.totalStaked)
      .div(new BigNumber(10).pow(poolData.stakedTokenDecimals))
      .times(new BigNumber(poolData.stakedTokenPrice))
      .times(new BigNumber(poolData.networkStablePrice))
      .toFormat(2)
  }

  const viewOnText = () => {
    return 'View on Explorer';
  }

  // const harvestLockup = () => {
  //   return new BigNumber(poolData.harvestInterval).div(3600).toFormat(2);
  // }

  return (
    <>
      <button type="button" className="button is-ghost is-fullwidth" onClick={toggleDetails}>
        <span className="icon-text">
          {isDetailsOpen ?  'Hide' : 'Details'}
          <span className="icon ml-1">
            <FontAwesomeIcon icon={ isDetailsOpen ? faAngleUp : faAngleDown } />
          </span>
        </span>
      </button>
      <div className={`py-3 ${isDetailsOpen ? null : 'is-hidden'}`}>
        <div className="level is-mobile mb-1">
          <div className="level-left">
            <div className="level-item">
              Pool Reward
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="is-flex is-flex-direction-column is-align-items-flex-end">
                <h6 className="is-size-6 has-text-weight-semibold">{ new BigNumber(poolData.poolReward).toFormat(2) }</h6>
                <h6 className="is-size-6 has-text-weight-semibold">~$ { new BigNumber(poolData.poolRewardStable).toFormat(2) }</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="level is-mobile mb-1">
          <div className="level-left">
            <div className="level-item">
              Total Liquidity
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <div className="is-flex is-flex-direction-column is-align-items-flex-end">
                <h6 className="is-size-6 has-text-weight-semibold">{ totalStaked() }</h6>
                <h6 className="is-size-6 has-text-weight-semibold">~$ { totalStakedStable() }</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons is-justify-content-center">
          <a href={ linkToViewOn() } className="button is-text" target="_blank" rel="noreferrer">
            <span className="icon-test">
              { viewOnText() }
              <span className="icon ml-1">
                <FontAwesomeIcon icon={ faExternalLinkAlt } />
              </span>
            </span>
          </a>
          <a className="button is-text" href={poolData.projectLink} target="_blank" rel="noreferrer">
            <span className="icon-text">
              Project
              <span className="icon ml-1">
                <FontAwesomeIcon icon={ faExternalLinkAlt } />
              </span>
            </span>
          </a>
        </div>
      </div>
    </>
  );
}

export default PoolCardFooterSection;
