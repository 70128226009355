import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faHandHoldingUsd, faTractor } from '@fortawesome/free-solid-svg-icons';

const MoneyRecipeSection = () => {
  return (
    <>
      <h4 className="has-text-centered is-size-4">Money Recipe</h4>
      <ul className="steps is-narrow is-medium is-centered has-content-centered">
        <li className="steps-segment has-gaps">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faCoins } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Buy BANKSY token</p>
            </div>
        </li>
        <li className="steps-segment has-gaps">
          <Link to="/pools" className="has-text-dark">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faTractor } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Stake on Banksy Owner pool</p>
            </div>
          </Link>
        </li>
        <li className="steps-segment has-gaps">
          <span className="steps-marker">
            <span className="icon">
              <FontAwesomeIcon icon={ faHandHoldingUsd } />
            </span>
          </span>
          <div className="steps-content">
            <p className="heading">EARN USDC.e</p>
          </div>
        </li>
      </ul>
    </>
  );
}

export default MoneyRecipeSection;
