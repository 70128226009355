import BigNumber from 'bignumber.js';
import { contractsMainNet, contractsTestNet } from '../config/contracts';
import { swapsMainNet, swapsTestNet } from '../config/swaps';

export const getAddress = (token) => {
  if (token.startsWith('0x')) {
    return token;
  }

  if (process.env.REACT_APP_ENV === 'PRODUCTION') {
    return contractsMainNet.get(token);
  }

  return contractsTestNet.get(token);
}

export const getLpAddress = (token, quoteToken) => getAddress(`${token}-${quoteToken}`) || getAddress(`${quoteToken}-${token}`);

export const swapAddress = (farmData) => {
  let swapUrls = swapsTestNet.get(farmData.swap);
  if (process.env.REACT_APP_ENV === 'PRODUCTION') {
    swapUrls = swapsMainNet.get(farmData.swap);
  }

  let params;

  const tokenAddress = getAddress(farmData.token);
  const quoteTokenAddress = getAddress(farmData.quoteToken);

  if (farmData.isTokenOnly) {
    params = tokenAddress;
  } else {
    let firstPart;
    let secondPart;

    if (farmData.token === process.env.REACT_APP_NETWORK_TOKEN || farmData.token === `w${process.env.REACT_APP_NETWORK_TOKEN}`) {
      firstPart = 'AVAX';
      secondPart = quoteTokenAddress;
    } else if (farmData.quoteToken === process.env.REACT_APP_NETWORK_TOKEN || farmData.quoteToken === `w${process.env.REACT_APP_NETWORK_TOKEN}`) {
      firstPart = 'AVAX';
      secondPart = tokenAddress;
    } else {
      firstPart = tokenAddress;
      secondPart = quoteTokenAddress;
    }

    params = `${firstPart}/${secondPart}`;
  }

  return swapUrls[farmData.isTokenOnly ? 'url' : 'urlLp'].replace(':token', params);
}

export const trunc = (value, mul, decimals) => {
  const dec = new BigNumber(10).pow(decimals);

  return (new BigNumber(value).times(mul).dividedBy(dec).times(dec))
    .integerValue(BigNumber.ROUND_DOWN).dividedBy(dec);
}

export const nativeTokenName = process.env.REACT_APP_NATIVE_TOKEN.toUpperCase().replace('V2', ' V2');
export const networkTokenName = process.env.REACT_APP_NETWORK_TOKEN.toUpperCase();
export const stableTokenName = process.env.REACT_APP_STABLE_TOKEN.toUpperCase();
