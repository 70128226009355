const swapsMainNet = new Map();
const swapsTestNet = new Map();

swapsMainNet.set('Pangolin', {
  url: 'https://app.pangolin.exchange/#/swap?outputCurrency=:token',
  urlLp: 'https://app.pangolin.exchange/#/add/:token',
});

swapsMainNet.set('Trader Joe', {
  url: 'https://traderjoexyz.com/trade?outputCurrency=:token',
  urlLp: 'https://www.traderjoexyz.com/pool/:token',
});

export { swapsMainNet, swapsTestNet };
