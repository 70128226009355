import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import preBanksyAbi from '../config/abis/preBanksy.json';
import preBanksySwapAbi from '../config/abis/preBanksySwap.json';
import masterChefAbi from '../config/abis/masterchef.json';
import nftSaleAbi from '../config/abis/nftSale.json';
import nftAirdropAbi from '../config/abis/nftAirdrop.json';
import erc20Abi from '../config/abis/erc20.json';
import nftChefAbi from '../config/abis/nftChef.json';
import { getAddress } from '../utils/commons';
import { getSignedContract, getWalletAddress } from './commons';

export const fetchPreSale = async () => {
  const walletAddress = await getWalletAddress();

  const preBanksyAddress = getAddress('preBanksy');
  const preBanksySwapAddress = getAddress('preBanksySwap');
  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
  const tokenAddress = getAddress('usdc');
  const masterChefAddress = getAddress('masterChef');
  const nftAirdropAddress = getAddress('nftAirdrop');
  const nftChefAddress = getAddress('nftChef');

  const preBanksyCalls = [
    {
      address: preBanksyAddress,
      name: 'startTime',
    },
    {
      address: preBanksyAddress,
      name: 'endTime',
    },
    {
      address: preBanksyAddress,
      name: 'preBanksyPerAccountMaxTotal',
    },
    {
      address: preBanksyAddress,
      name: 'preBanksyRemaining',
    },
    {
      address: preBanksyAddress,
      name: 'decimals'
    },
    {
      address: preBanksyAddress,
      name: 'balanceOf',
      params: [preBanksyAddress],
    },
    {
      address: preBanksyAddress,
      name: 'earlyAccessTwoTime',
    },
    {
      address: preBanksyAddress,
      name: 'earlyAccessThreeTime',
    },
    {
      address: preBanksyAddress,
      name: 'earlyAccessOnePrice',
    },
    {
      address: preBanksyAddress,
      name: 'earlyAccessTwoPrice',
    },
    {
      address: preBanksyAddress,
      name: 'earlyAccessThreePrice',
    },
  ];

  if (walletAddress !== null) {
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'allowance',
      params: [
        walletAddress,
        preBanksySwapAddress,
      ],
    });
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    preBanksyCalls.push({
      address: preBanksyAddress,
      name: 'userPreBanksyTotally',
      params: [walletAddress],
    });
  }

  const preBanksySwapCalls = [
    {
      address: preBanksySwapAddress,
      name: 'startTime',
    },
  ];

  const erc20Calls = [
    {
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [preBanksySwapAddress],
    },
    {
      address: nativeTokenAddress,
      name: 'decimals',
    },
    {
      address: tokenAddress,
      name: 'decimals',
    },
  ];

  if (walletAddress !== null) {
    erc20Calls.push({
      address: tokenAddress,
      name: 'allowance',
      params: [
        walletAddress,
        preBanksyAddress,
      ],
    });
    erc20Calls.push({
      address: tokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
    erc20Calls.push({
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: [walletAddress],
    });
  }

  const masterChefCalls = [
    {
      address: masterChefAddress,
      name: 'startTime',
    }
  ];

  const nftSaleCalls = [];
  const nftChefCalls = [];

  for(let i = 0; i < 1; i++) {
    nftSaleCalls.push({
      address: getAddress(`nftSale${i}`),
      name: 'startTime',
    });
    nftChefCalls.push({
      address: nftChefAddress,
      name: 'balanceOf',
      params: [getAddress(`nftSale${i}`)],
    });
  }

  nftChefCalls.push({
    address: nftChefAddress,
    name: 'balanceOf',
    params: [nftAirdropAddress],
  });

  const preBanksyResults = await multicall(preBanksyAbi, preBanksyCalls);
  const preBanksySwapResults = await multicall(preBanksySwapAbi, preBanksySwapCalls);
  const erc20Results = await multicall(erc20Abi, erc20Calls);
  const masterChefResults = await multicall(masterChefAbi, masterChefCalls);
  const nftSaleResults = await multicall(nftSaleAbi, nftSaleCalls);
  const nftChefResults = await multicall(nftChefAbi, nftChefCalls);
  const nftAirdropResults = await multicall(nftAirdropAbi, [{
    address: nftAirdropAddress,
    name: 'startTime',
  }]);

  const earlyAccessTwoTime = new BigNumber(preBanksyResults[6] || 0);
  const earlyAccessThreeTime = new BigNumber(preBanksyResults[7] || 0);

  const currentTime = Date.now() / 1000;

  let salePrice = new BigNumber(preBanksyResults[8] || 0);
  let currentStage = new BigNumber(1);

  if (earlyAccessThreeTime.gt(0) && earlyAccessThreeTime.lt(currentTime)) {
    salePrice = new BigNumber(preBanksyResults[10] || 0);
    currentStage = new BigNumber(3);
  } else if (earlyAccessTwoTime.gt(0) && earlyAccessTwoTime.lt(currentTime)) {
    salePrice = new BigNumber(preBanksyResults[9] || 0);
    currentStage = new BigNumber(2);
  }

  return {
    firstLoad: false,

    preBanksyStartTime: new BigNumber(preBanksyResults[0] || 0).toJSON(),
    preBanksyEndTime: new BigNumber(preBanksyResults[1] || 0).toJSON(),
    preBanksyPerAccountMaxTotal: new BigNumber(preBanksyResults[2] || 0).toJSON(),
    preBanksyRemaining: new BigNumber(preBanksyResults[3] || 0).toJSON(),
    preBanksyDecimals: preBanksyResults[4] ? preBanksyResults[4][0] : 0,
    preBanksyBalance: new BigNumber(preBanksyResults[5] || 0).toJSON(),

    userPreBanksySwapAllowance: new BigNumber(preBanksyResults[11] || 0).toJSON(),
    userPreBanksyBalance: new BigNumber(preBanksyResults[12] || 0).toJSON(),
    userPreBanksyTotally: new BigNumber(preBanksyResults[13] || 0).toJSON(),

    salePrice: salePrice.toJSON(),
    currentStage: currentStage.toJSON(),
    earlyAccessTwoTime: earlyAccessTwoTime.toJSON(),
    earlyAccessThreeTime: earlyAccessThreeTime.toJSON(),

    preBanksySwapStartTime: new BigNumber(preBanksySwapResults[0] || 0).toJSON(),

    preBanksySwapBalance: new BigNumber(erc20Results[0] || 0).toJSON(),
    nativeTokenDecimals: erc20Results[1] ? erc20Results[1][0] : 0,
    tokenDecimals: erc20Results[2] ? erc20Results[2][0] : 0,
    userPreBanksyAllowance: new BigNumber(erc20Results[3] || 0).toJSON(),
    userTokenBalance: new BigNumber(erc20Results[4] || 0).toJSON(),
    userNativeTokenBalance: new BigNumber(erc20Results[5] || 0).toJSON(),

    masterChefStartTime: new BigNumber(masterChefResults[0] || 0).toJSON(),

    nftSale0StartTime: new BigNumber(nftSaleResults[0] || 0).toJSON(),
    nftSale0BalanceOf: new BigNumber(nftChefResults[0] || 0).toJSON(),
    // nftSale1StartTime: new BigNumber(nftSaleResults[1] || 0).toJSON(),
    // nftSale1BalanceOf: new BigNumber(nftChefResults[1] || 0).toJSON(),
    // nftSale2StartTime: new BigNumber(nftSaleResults[2] || 0).toJSON(),
    // nftSale2BalanceOf: new BigNumber(nftChefResults[2] || 0).toJSON(),

    nftAirdropStartTime: new BigNumber(nftAirdropResults[0] || 0).toJSON(),
    nftAirdropBalanceOf: new BigNumber(nftChefResults[nftChefResults.length - 1] || 0).toJSON(),

    soldOut: new BigNumber(preBanksyResults[3] || 0).eq(0),
  };
}

export const approveBuyPreBanksy = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('preBanksy'), ethers.constants.MaxUint256);
}

export const approveSwapPreBanksy = async () => {
  const tokenContract = await getSignedContract('preBanksy', preBanksyAbi);
  return await tokenContract.approve(getAddress('preBanksySwap'), ethers.constants.MaxUint256);
}

export const swapPreBanksyForBanksy = async () => {
  const preBanksySwapContract = await getSignedContract('preBanksySwap', preBanksySwapAbi);
  return await preBanksySwapContract.swapPreBanksyForBanksy();
}

export const buyPreBanksy = async (amount) => {
  const preBanksyContract = await getSignedContract('preBanksy', preBanksyAbi);
  return await preBanksyContract.buyPreBanksy(amount);
}
