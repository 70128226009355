import React, { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVrCardboard } from '@fortawesome/free-solid-svg-icons';

import { getAddress } from '../../../utils/commons';
import { approveNft } from '../../../blockchain/nft';

import { ToastContext } from '../../../context/toast';
import { VrContext } from '../../../context/vr';
import { nftImage, nftName } from '../../../utils/nft';

const NftMergeCard = ({ nftData, ribbon = '', selected = false, disabled = false, toggleArtWorkId, hideActions = false }) => {
  const { addToast } = useContext(ToastContext);
  const { toggleVrModal } = useContext(VrContext);

  const [pendingTx, setPendingTx] = useState(false);
  const nftMergeAddress = getAddress('nftMerge');

  const renderVrPreview = () => {
    if (nftData.generation !== 1) {
      return null;
    }

    return (
      <button
        type="button"
        className="button is-text ml-auto"
        onClick={ toggleVrModal }
      >
        <span className="icon">
          <FontAwesomeIcon icon={ faVrCardboard } />
        </span>
      </button>
    );
  }

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approveNft(nftMergeAddress, nftData.pid);
      await tx.wait();
      addToast('ArtWork Approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('ArtWork Approve failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderActions = () => {
    if (nftData.mergeCount > 1) {
      return (<p className="has-primary-text">Merged ArtWork</p>);
    }

    if (nftData.allowance !== nftMergeAddress) {
      return (
        <button
          className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
          disabled={ pendingTx || disabled }
          onClick={ handleApprove }
        >
          Approve
        </button>
      );
    }

    return (
      <div className="field">
        <input
          id={ `pid-${nftData.pid}` }
          type="checkbox"
          name={ `pid-${nftData.pid}` }
          className="switch is-rounded is-outlined"
          checked={ selected }
          onChange={ () => toggleArtWorkId(nftData.pid, nftData.generation) }
          disabled={ disabled && !selected }
        />
        <label htmlFor={ `pid-${nftData.pid}` }>Select</label>
      </div>
    );
  }

  return (
    <div className={ `box p-3 is-relative ${nftData.mergeCount > 0 ? 'gradient-border' : ''}` }>
      { ribbon !== '' ? (
        <div className="ribbon ribbon-top-right">
          <span>{ ribbon }</span>
        </div>
      ) : null }
      { nftImage(nftData) }
      <p className="title mb-0 is-flex">
        { nftName(nftData) }
        { renderVrPreview() }
      </p>
      <div className="field is-grouped is-grouped-multiline p-3">
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Gen.</span>
            <span className="tag mb-0 is-info">{ nftData.generation }</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Exp.</span>
            <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).toFormat(0) }</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Power</span>
            <span className="tag mb-0 is-primary">{ nftData.power }</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Boost Stake</span>
            <span className="tag mb-0 is-danger">{ new BigNumber(nftData.boostStake).toFormat(2) }%</span>
          </div>
        </div>
        <div className="control">
          <div className="tags has-addons">
            <span className="tag mb-0 is-dark">Merged</span>
            <span className="tag mb-0 is-link">{ new BigNumber(nftData.mergeCount).toFormat() }</span>
          </div>
        </div>
      </div>
      {hideActions ? null : (
        <div className="p-3">
          { renderActions() }
        </div>
      )}
    </div>
  );
}

export default NftMergeCard;
