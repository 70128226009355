import React, { useState, useContext, useEffect } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

import NftNav from './components/NftNav';
import NftMergeCard from './components/NftMergeCard';
import NftInfo from './components/NftInfo';
import { ToastContext } from '../../context/toast';

import { fetchNftMerge, claimNftMerged, mergeNftArtWork } from '../../blockchain/nft';
import { experienceScale } from '../../utils/nft';

const NftMergePage = () => {
  const { addToast } = useContext(ToastContext);
  const [filter, setFilter] = useState('to-merge');
  const [generation, setGeneration] = useState(-1);
  const [selectedArtWorks, setSelectedArtWorks] = useState({
    count: 0,
    items: []
  });
  const [pendingTx, setPendingTx] = useState(false);
  const [started, setStarted] = useState(false);
  const [nftMergeState, setNftMergeState] = useState({
    loading: false,
    nfts: [],
    firstLoad: true,
    pendingClaim: 999999,
    pendingProcess: false,
    nftClaim: {},
  });

  useEffect(() => {
    const syncNftMerge = async () => {
      setNftMergeState(prevState => ({ ...prevState, loading: true }));
      const rs = await fetchNftMerge();
      setNftMergeState(prevState => ({ ...prevState, ...rs, loading: false }));
    }

    const intervalId = setInterval(syncNftMerge, 10000);

    syncNftMerge();

    return () => clearInterval(intervalId);
  }, [setNftMergeState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;

      setStarted(prevState => prevState || Number(process.env.REACT_APP_NFT_MERGE_START) < currentTime);
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [setStarted]);

  const toggleArtWorkId = (artWorkId, newGeneration) => {
    const prevState = selectedArtWorks;
    const idx = prevState.items.indexOf(artWorkId);
    if (idx > -1) {
      prevState.items.splice(idx, 1);
    } else {
      prevState.items.push(artWorkId);
      if (generation < 0) {
        setGeneration(newGeneration);
      }
    }
    prevState.count = prevState.items.length;
    if (prevState.count === 0) {
      setGeneration(-1);
    }

    setSelectedArtWorks(oldState => ({ oldState, ...prevState }));
  }

  const handleClaimNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await claimNftMerged();
      await tx.wait();
      addToast('Merged NFT claim succeeded', 'is-success');
      showConfetti();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('Merged NFT claim failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const handleMergeNftArtWork = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await mergeNftArtWork(selectedArtWorks.items);
      await tx.wait();
      addToast('NFT Merge succeeded', 'is-success');
      setGeneration(-1);
      setSelectedArtWorks({
        items: [],
        count: 0,
      });
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT Merge failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const showConfetti = () => {
    confetti({
      resize: true,
      particleCount: 200,
      startVelocity: 30,
      gravity: 0.5,
      spread: 350,
      origin: {
        x: 0.5,
        y: 0.3,
      },
    });
  }

  const isLoading = () => nftMergeState.firstLoad && nftMergeState.loading;

  const filteredNfts = () => {
    let newNfts = nftMergeState.nfts;

    if (filter === 'to-merge') {
      newNfts = newNfts.filter(nft => new BigNumber(nft.mergeCount).lt(2));
    }

    if (filter === 'merged') {
      newNfts = newNfts.filter(nft => new BigNumber(nft.mergeCount).gt(1));
    }

    return newNfts;
  }

  const renderResults = () => {
    if (isLoading()) {
      return (
        <div className="columns is-justify-content-center">
          <span className="icon-text is-align-items-center">
            <span className="icon is-large">
              <FontAwesomeIcon icon={ faCog } spin size="2x" />
            </span>
            <span>Loading...</span>
          </span>
        </div>
      );
    }

    if (nftMergeState.step !== 1) {
      return null;
    }

    if (nftMergeState.nfts.length > 0) {
      return (
        <>
          <div className="tabs is-toggle">
            <ul>
              <li className={ filter === 'to-merge' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'to-merge') {
                      setFilter('to-merge');
                    }
                  }}
                >
                  To Merge
                </a>
              </li>
              <li className={ filter === 'merged' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'merged') {
                      setFilter('merged');
                    }
                  }}
                >
                  Merged
                </a>
              </li>
              <li className={ filter === 'all' ? 'is-active' : '' }>
                <a
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    if (filter !== 'all') {
                      setFilter('all');
                    }
                  }}
                >
                  All
                </a>
              </li>
            </ul>
          </div>
          <div className="columns is-multiline is-justify-content-center">
            {filteredNfts().map(nftData => (
              <div key={ `nft-${nftData.generation}-${nftData.pid}` } className="column is-one-third">
                <NftMergeCard
                  nftData={ nftData }
                  toggleArtWorkId={ toggleArtWorkId }
                  selected={ selectedArtWorks.items.includes(nftData.pid) }
                  disabled={ !started || selectedArtWorks.count >= 3 || (generation >= 0 && !new BigNumber(nftData.generation).eq(generation)) }
                />
              </div>
            ))}
          </div>
        </>
      );
    }

    return (
      <NftMergeCard
        nftData={{
          pid: 2110,
          experience: 287,
          generation: 1,
          power: 27,
          mergedCount: 0,
        }}
        ribbon="ON SALE"
        ribbonLink="/nft-sale"
        hideActions
      />
    );
  }

  const preview = () => {
    let pid = 999999;
    let experience = new BigNumber(0);
    let gen = 1;
    let powerArr = [];
    let mergeCount = 0;

    for (let i = 0; i < selectedArtWorks.count; i++) {
      const artWork = nftMergeState.nfts.find(nft => nft.pid === selectedArtWorks.items[i]);
      if (i === 0) {
        pid = artWork.pid;
        gen = artWork.generation;
      }
      experience = experience.plus(artWork.experience);
      powerArr.push(artWork.power);
      if (new BigNumber(artWork.mergeCount).gt(mergeCount)) {
        mergeCount = new BigNumber(artWork.mergeCount).toNumber();
      }
    }
    mergeCount += 1;

    let power = 0;
    if (powerArr.length > 0) {
      const avg = powerArr.reduce((a, b) => a + b, 0) / powerArr.length;
      power = Math.max(...powerArr) + Math.floor(avg / (mergeCount + 1));
    }

    const boostStake = new BigNumber(power).times(5).plus(experienceScale(experience)).div(100);

    return {
      pid,
      experience: experience.toJSON(),
      generation: gen,
      power,
      mergeCount,
      boostStake,
    }
  }

  return (
    <>
      <NftNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">Merge NFTs ArtWorks</p>
              <p className="subtitle"><span className="has-text-primary">NFT's ArtWorks</span> power will be unique and generated randomly.</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          {started ? null : (
            <div className="box has-text-centered">
              <p className="title has-text-primary">
                <Countdown date={ new BigNumber(process.env.REACT_APP_NFT_MERGE_START).times(1000).toNumber() } />
              </p>
              <p className="subtitle">
                Countdown until start
              </p>
            </div>
          )}
          <div className="columns">
            <div className="column">
              <div className="box content">
                <h4>Merge Steps</h4>
                <ol>
                  <li className={ nftMergeState.step === 1 ? 'has-text-success' : '' }>
                    Select NFT Artworks to Merge {nftMergeState.step === 1 ? `${selectedArtWorks.count}` : ''}
                  </li>
                  <li className={ nftMergeState.step === 2 ? 'has-text-success' : '' }>
                    <span className="icon-text">
                      Please wait while we are merging.
                      {nftMergeState.step === 2 ? (
                        <span className="icon ml-2">
                          <FontAwesomeIcon icon={ faCog } spin />
                        </span>
                      ) : null}
                    </span>
                  </li>
                  <li className={ nftMergeState.step === 3 ? 'has-text-success' : '' }>
                    Congrats! You can claim your new NFT ArtWork Boosted.
                  </li>
                </ol>
                <h4>How it works</h4>
                <ul>
                  <li>You'll be able to merge up to 3 ArtWorks at a time.</li>
                  <li>Select your Artworks and see in real time how the new one boosted will be look.</li>
                  <li>A ArtWork can only be merged once. This mean that once you get a merged ArtWork, it wont be eligible to merge again.</li>
                  <li>Merging is only available with same generation ArtWorks.</li>
                  <li><a href="https://docs.banksydao.finance/nfts/nft-merge" target="_blank" rel="noreferrer">Learn more...</a></li>
                </ul>
              </div>
            </div>
            {nftMergeState.step === 1 ? (
              <div className="column is-one-third">
                <NftMergeCard nftData={ preview() } hideActions />
                <button
                  type="button"
                  // disabled={ pendingTx || selectedArtWorks.count < 2 }
                  disabled
                  onClick={ handleMergeNftArtWork }
                  className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
                >
                  V2 is coming....
                </button>
              </div>
            ) : null}
            {nftMergeState.step === 3 ? (
              <div className="column is-one-third">
                <NftMergeCard nftData={ nftMergeState.nftClaim } hideActions />
                <button
                  type="button"
                  disabled={ pendingTx }
                  onClick={ handleClaimNft }
                  className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
                >
                  Claim
                </button>
              </div>
            ) : null}
          </div>
          { renderResults() }
          <div className="columns is-justify-content-center">
            <div className="column is-half">
              <div className="message is-info">
                <NftInfo />
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")', height: '100vh' }} />
    </>
  );
}

export default NftMergePage;
