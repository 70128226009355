const vaultInitialState = {
  loading: false,
  firstLoad: true,
  tvl: 0,
  maxFarmApy: 0,
  totalBuyBackAmount: 0,
  vaults: [
    {
      pid: 1,
      farmPid: 2,
      token: 'banksy',
      quoteToken: 'avax',
      isTokenOnly: false,
      name: 'BANKSY-WAVAX LP',
      swap: 'Trader Joe',
      from: 'Banksy',
      strategy: '0x97F460261dA69C1C557BD87E59662d9B76114A10',
    },
    {
      pid: 0,
      farmPid: 1,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BANKSY-USDC.e LP',
      swap: 'Trader Joe',
      from: 'Banksy',
      strategy: '0xf7b15c4bCb40006Ba3769A1C9531e096a02F3f9d',
    },
  ],
}

export default vaultInitialState;
