import React, { useEffect, useContext } from 'react';
import HomeNav from '../home/components/HomeNav';

import CountdownSection from './components/CountdownSection';
import FarmsStakingSection from './components/FarmsStakingSection';
import TotalValueLockedSection from './components/TotalValueLockedSection';
import TokenStatsSection from './components/TokenStatsSection';
import TreasurySection from './components/TreasurySection';
import NewsSection from './components/NewsSection';
import EarnUpToSection from './components/EarnUpToSection';
import MoneyRecipeSection from './components/MoneyRecipeSection';

import { FarmsContext } from '../../context/farms';
import { fetchFarms } from '../../blockchain/farms';
import { PoolsContext } from '../../context/pools';
import { fetchPools } from '../../blockchain/pools';
import { VaultsContext } from '../../context/vaults';
import { fetchVaults } from '../../blockchain/vaults';

import './DashboardPage.css';

function DashboardPage() {
  const { farmState, setFarmState } = useContext(FarmsContext);
  const { poolState, setPoolState } = useContext(PoolsContext);
  const { vaultState, setVaultState } = useContext(VaultsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncData = async () => {
      setFarmState(prevState => ({ ...prevState, loading: true }));
      const farmsResult = await fetchFarms();
      setFarmState(prevState => ({ ...prevState, ...farmsResult, loading: false }));

      setPoolState(prevState => ({ ...prevState, loading: true }));
      const poolsResult = await fetchPools();
      setPoolState(prevState => ({ ...prevState, ...poolsResult, loading: false }));

      setVaultState(prevState => ({ ...prevState, loading: true }));
      const vaultsResult = await fetchVaults();
      setVaultState(prevState => ({ ...prevState, ...vaultsResult, loading: false }));
    }

    const intervalId = setInterval(syncData, 10000);

    syncData();

    return () => clearInterval(intervalId);
  }, [setFarmState, setPoolState, setVaultState]);

  return (
    <>
      <HomeNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">BanksyDAO Finance</p>
              <p className="subtitle">
                THE FIRST <span className="has-text-primary">DUAL TOKEN</span> WITH <span className="has-text-primary">OWNER TREASURY</span>.
              </p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          {/* <figure class="image mb-5">
            <a href="https://ftm.banksydao.finance">
              <img src="/images/banner.gif" alt="Banksy FTM V2" />
            </a>
          </figure> */}
          <MoneyRecipeSection />
          <CountdownSection />
          <FarmsStakingSection farmState={ farmState } />
          <div className="columns">
            <div className="column is-two-third">
              <TotalValueLockedSection farmState={ farmState } poolState={ poolState } vaultState={ vaultState } />
              <TreasurySection farmState={ farmState } />
            </div>
            <div className="column is-one-third">
              <NewsSection />
            </div>
          </div>
          <TokenStatsSection farmState={ farmState } />
          <EarnUpToSection farmState={ farmState } poolState={ poolState } vaultState={ vaultState } />
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-0.png")', height: '100vh' }} />
    </>
  );
}

export default DashboardPage;
