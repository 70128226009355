const poolInitialState = {
  loading: false,
  tvl: 0,
  maxPoolApr: 0,
  firstLoad: true,
  pools: [
    {
      pid: 2,
      address: '0xdE34Fd16aa617D2e62681077bf2BDfa671F96461',
      stakedToken: 'banksy',
      rewardToken: 'dai',
      name: 'BANKSY DAI.e WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v2',
    },
    {
      pid: 1,
      address: '0x451D370AD3D3A344e28cFa124b8Fde598Fe9696A',
      stakedToken: 'preBanksy',
      rewardToken: 'dai',
      name: 'PRE-BANKSY DAI.e WALL',
      projectLink: 'https://makerdao.com/en/',
      category: 'CORE',
      version: 'v2',
      stakedTokenDefaultPrice: 20.00,
    },
  ],
};

export default poolInitialState;
