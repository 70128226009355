import BigNumber from 'bignumber.js';
import multicall from '../blockchain/multicall';
import erc20Abi from '../config/abis/erc20.json';
import farmInitialState from '../state/farmInitialState';
import { getAddress, getLpAddress } from '../utils/commons';

const ZERO = new BigNumber(0);

export const fetchTreasury = async () => {
  let erc20Calls = [];

  const treasuryDAOAddress = getAddress('treasuryDAO');
  const usdcAddress = getAddress('usdc');

  const erc20BaseCalls = [
    {
      address: usdcAddress,
      name: 'balanceOf',
      params: [treasuryDAOAddress]
    }
  ];

  const treasuryUtilAddress = getAddress('treasuryUtil');

  farmInitialState.farms.forEach(farm => {
    const tokenAddress = getAddress(farm.token);
    const quoteTokenAddress = getAddress(farm.quoteToken);
    const lpAddress = getLpAddress(farm.token, farm.quoteToken);

    const calls = [
      {
        address: tokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: quoteTokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: tokenAddress,
        name: 'decimals',
      },
      {
        address: quoteTokenAddress,
        name: 'decimals',
      },
      {
        address: farm.isTokenOnly ? tokenAddress : lpAddress,
        name: 'balanceOf',
        params: [treasuryUtilAddress],
      },
      {
        address: lpAddress,
        name: 'totalSupply',
      },
    ];

    erc20Calls = [...erc20Calls, ...calls];
  });

  const erc20Results = await multicall(erc20Abi, [...erc20Calls, ...erc20BaseCalls]);

  const erc20Length = (erc20Results.length - erc20BaseCalls.length) / farmInitialState.farms.length;

  let tvl = ZERO;
  let nativeTokenPrice = ZERO;
  let networkTokenPrice = ZERO;

  const tor = new BigNumber(erc20Results[erc20Results.length - 1]).div(new BigNumber(10).pow(6));

  const newFarms = farmInitialState.farms.map((farm, i) => {
    const erc20Index = i * erc20Length;

    const tokenBalanceLP = new BigNumber(erc20Results[erc20Index + 0]);
    const quoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 1]);
    const tokenDecimals = erc20Results[erc20Index + 2][0];
    const quoteTokenDecimals = erc20Results[erc20Index + 3][0];
    const lpTokenBalanceMC = new BigNumber(erc20Results[erc20Index + 4]);
    const lpTotalSupply = new BigNumber(erc20Results[erc20Index + 5]);

    let tokenAmount;
    let lpTotalInQuoteToken;
    let tokenPriceVsQuote;

    if (farm.isTokenOnly) {
      tokenAmount = lpTokenBalanceMC.div(new BigNumber(10).pow(tokenDecimals));
      
      if (farm.token === process.env.REACT_APP_STABLE_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        tokenPriceVsQuote = new BigNumber(1);
      } else {
        tokenPriceVsQuote = quoteTokenBalanceLP.div(tokenBalanceLP);
      }
      lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
    } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = lpTokenBalanceMC.div(lpTotalSupply);

      // Total value in staking in quote token value
      lpTotalInQuoteToken = quoteTokenBalanceLP
        .div(new BigNumber(10).pow(tokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      tokenAmount = tokenBalanceLP.div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio);
      const quoteTokenAmount = quoteTokenBalanceLP
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio);

      if (tokenAmount.gt(0)) {
        tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
      } else {
        tokenPriceVsQuote = quoteTokenBalanceLP
          .div(new BigNumber(tokenBalanceLP))
          .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals));
      }
    }

    if (!farm.isTokenOnly) {
      if (farm.token === process.env.REACT_APP_NATIVE_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        if (tokenPriceVsQuote.isNaN()) {
          tokenPriceVsQuote = new BigNumber(process.env.REACT_APP_DEFAULT_PRICE);
        }
        nativeTokenPrice = tokenPriceVsQuote;
      }

      if (farm.token === process.env.REACT_APP_NETWORK_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        networkTokenPrice = tokenPriceVsQuote;
      }
    }

    return {
      ...farm,
      tokenAmount: tokenAmount.toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken
        .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        .toJSON(),
      tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
      tokenDecimals,
      quoteTokenDecimals,
    };
  });

  newFarms.map(farm => {
    let totalValue = new BigNumber(farm.lpTotalInQuoteToken);
    if (totalValue.isNaN()) {
      totalValue = ZERO;
    }
    if (farm.quoteToken === process.env.REACT_APP_NETWORK_TOKEN) {
      totalValue = networkTokenPrice.times(totalValue);
    } else if (farm.quoteToken === process.env.REACT_APP_NATIVE_TOKEN) {
      totalValue = nativeTokenPrice.times(totalValue);
    }

    tvl = tvl.plus(totalValue);

    return {
      ...farm,
      totalValue: totalValue.toJSON(),
    };
  });

  return {
    tvl: tvl.toJSON(),
    tor: tor.toJSON(),
    firstLoad: false,
  }
};
