const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0xdb77413ecac26A6F6e15811bBC648d20F662729d');
contractsMainNet.set('multiCall', '0x4EDAa97837797b51eA626d1dD62793145F1b3f1a');
contractsMainNet.set('treasuryDAO', '0x094788F7f3330d4554A51bD847790236cdAeab35');
contractsMainNet.set('treasuryUtil', '0xE2F322E2D504ee4c46e00F69f4ca43C54b34B44a');

contractsMainNet.set('vaultChef', '0x5b9A3ab2F2D55219F4a1F6258ec05eD4e8aaefB2');

contractsMainNet.set('nftChef', '0x942d791ab07e33Fe4B780Fc0b3874a24Ac3da433');
contractsMainNet.set('nftSale0', '0x7fa26A6C70A6DB9e87730F27336ec3800C8c7b7a');
contractsMainNet.set('nftSale1', '0x26C06b2dDFf4a783834f4088ce5f733092514104');
contractsMainNet.set('nftSale2', '0xe0F6a055D389587f5d130B0F771473a0Ff7bB80b');
// contractsMainNet.set('nftSale3', '0xb4827169A802B05bc6605a20C3e246d35955Adce');
contractsMainNet.set('nftAirdrop', '0xD55345E4a940eD3574DFC995Ad75A4EF23C99465');
contractsMainNet.set('nftMerge', '0x503d2E56055c7078905369D7fA43c6f20C70a43a');

contractsMainNet.set('preBanksy', '0xbdF05B5669E81461185cc35072F6a61BBe370C4b');
contractsMainNet.set('preBanksySwap', '0xB9A08F3A356F211dD43D6280E1Db383cE416354a');
// single tokens
contractsMainNet.set('banksy', '0x46364863343c42f4e503F5076D24d7c0bd8cEc85');
contractsMainNet.set('avax', '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7');
contractsMainNet.set('eth', '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB');
contractsMainNet.set('btc', '0x50b7545627a5162f82a992c33b87adc75187b218');
contractsMainNet.set('dai', '0xd586e7f844cea2f87f50152665bcbc2c279d8d70');
contractsMainNet.set('usdc', '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664');
contractsMainNet.set('link', '0x5947BB275c521040051D82396192181b413227A3');
contractsMainNet.set('usdt', '0xc7198437980c041c805a1edcba50c1ce5db95118');
contractsMainNet.set('banksyv1', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
// LPs
contractsMainNet.set('banksy-avax', '0x62F8955079cAAFe49F19b3800886D426DB35A507');
contractsMainNet.set('banksy-usdc', '0x5F2F0c845980f78ecD91e2082652FC8077307601');
contractsMainNet.set('avax-usdc', '0xA389f9430876455C36478DeEa9769B7Ca4E3DDB1');
contractsMainNet.set('avax-usdt', '0xeD8CBD9F0cE3C6986b22002F03c6475CEb7a6256');
contractsMainNet.set('avax-dai', '0x87Dee1cC9FFd464B79e058ba20387c1984aed86a');
contractsMainNet.set('usdt-usdc', '0x2E02539203256c83c7a9F6fA6f8608A32A2b1Ca2');
contractsMainNet.set('link-avax', '0x6F3a0C89f611Ef5dC9d96650324ac633D02265D3');
contractsMainNet.set('btc-avax', '0xd5a37dc5c9a396a03dd1136fc76a1a02b1c88ffa');
contractsMainNet.set('eth-avax', '0xfe15c2695f1f920da45c30aae47d11de51007af9');
contractsMainNet.set('banksyv1-avax', '0xF379b14A5f366822fA435320CE10cBCB07E24749');
// Fake
contractsMainNet.set('preBanksy-avax', '0x62F8955079cAAFe49F19b3800886D426DB35A507'); // for pool#1
// contractsMainNet.set('fdai-avax', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// contractsMainNet.set('fdai', '0x3573E1C88D66073F1d5d497a12EB7eb796a244CE');
// contractsMainNet.set('fusdc', '0x0fCE427cB6d4DDd915dEe4F1DAd00cF04179C436');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-avax', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
