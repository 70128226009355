import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import multicall from '../blockchain/multicall';
import erc20Abi from '../config/abis/erc20.json';
import nativeTokenAbi from '../config/abis/nativeToken.json';
import masterChefAbi from '../config/abis/masterchef.json';
import { getAddress, getLpAddress } from '../utils/commons';
import farmInitialState from '../state/farmInitialState';
import { getSignedContract, getWalletAddress } from './commons';

import { getNfts } from './nft';

const ZERO = new BigNumber(0);

export const fetchFarms = async () => {
  let erc20Calls = [];
  let masterChefCalls = [];

  const walletAddress = await getWalletAddress();
  const isUserConnected = walletAddress !== null;

  const masterChefAddress = getAddress('masterChef');
  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);

  const erc20BaseCalls = [
    {
      address: nativeTokenAddress,
      name: 'maxUserTransferAmount',
    },
    {
      address: nativeTokenAddress,
      name: 'maxUserTransferAmountRate',
    },
    {
      address: nativeTokenAddress,
      name: 'totalSupply',
    },
    {
      address: nativeTokenAddress,
      name: 'balanceOf',
      params: ['0x000000000000000000000000000000000000dEaD'],
    },
  ];

  const masterChefBaseCalls = [
    {
      address: masterChefAddress,
      name: 'totalUSDCCollected',
    },
    {
      address: masterChefAddress,
      name: 'experienceRate',
    },
    {
      address: masterChefAddress,
      name: 'tokenPerSecond',
    },
    {
      address: masterChefAddress,
      name: 'tokenMaximumSupply',
    },
  ];

  if (isUserConnected) {
    masterChefBaseCalls.unshift({
        address: masterChefAddress,
        name: 'pendingUSD',
        params: [walletAddress],
    });
  }

  farmInitialState.farms.forEach(farm => {
    const tokenAddress = getAddress(farm.token);
    const quoteTokenAddress = getAddress(farm.quoteToken);
    const lpAddress = getLpAddress(farm.token, farm.quoteToken);

    const calls = [
      {
        address: tokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: quoteTokenAddress,
        name: 'balanceOf',
        params: [lpAddress],
      },
      {
        address: tokenAddress,
        name: 'decimals',
      },
      {
        address: quoteTokenAddress,
        name: 'decimals',
      },
      {
        address: farm.isTokenOnly ? tokenAddress : lpAddress,
        name: 'balanceOf',
        params: [masterChefAddress],
      },
      {
        address: lpAddress,
        name: 'totalSupply',
      },
    ];

    if(isUserConnected) {
      calls.push(
        {
          address: farm.isTokenOnly ? tokenAddress : lpAddress,
          name: 'allowance',
          params: [
            walletAddress,
            masterChefAddress
          ],
        },
      );
      calls.push(
        {
          address: farm.isTokenOnly ? tokenAddress : lpAddress,
          name: 'balanceOf',
          params: [walletAddress],
        },
      );
    }

    erc20Calls = [...erc20Calls, ...calls];

    const calls2 = [
      {
        address: masterChefAddress,
        name: 'poolInfo',
        params: [farm.pid],
      },
      {
        address: masterChefAddress,
        name: 'totalAllocPoint',
      },
    ];

    if(isUserConnected) {
      calls2.push(
        {
          address: masterChefAddress,
          name: 'userInfo',
          params: [
            farm.pid,
            walletAddress
          ],
        },
      );
      calls2.push(
        {
          address: masterChefAddress,
          name: 'pendingToken',
          params: [
            farm.pid,
            walletAddress
          ],
        },
      );
      calls2.push(
        {
          address: masterChefAddress,
          name: 'canHarvest',
          params: [
            farm.pid,
            walletAddress
          ],
        },
      );
    }

    masterChefCalls = [...masterChefCalls, ...calls2];
  });

  const erc20Results = await multicall(nativeTokenAbi, [...erc20Calls, ...erc20BaseCalls]);
  const masterchefResults  = await multicall(masterChefAbi, [...masterChefCalls, ...masterChefBaseCalls]);

  const erc20Length = (erc20Results.length - erc20BaseCalls.length) / farmInitialState.farms.length;
  const masterChefLength = (masterchefResults.length - masterChefBaseCalls.length) / farmInitialState.farms.length;

  let maxFarmApr = ZERO;
  let tvl = ZERO;
  let tnl = ZERO;
  let nativeTokenPrice = ZERO;
  let networkTokenPrice = ZERO;
  let nativeTokenPriceDefault = false;

  const maxUserTransferAmount = new BigNumber(erc20Results[erc20Results.length - 4]);
  const maxUserTransferAmountRate = new BigNumber(erc20Results[erc20Results.length - 3]);
  const tokenTotalSupply = new BigNumber(erc20Results[erc20Results.length - 2]);
  const tokenTotalBurned = new BigNumber(erc20Results[erc20Results.length - 1]);
  const pendingUSD = isUserConnected ? new BigNumber(masterchefResults[masterchefResults.length - 5]) : ZERO;
  const totalUSDCCollected = new BigNumber(masterchefResults[masterchefResults.length - 4]);
  const experienceRate = new BigNumber(masterchefResults[masterchefResults.length - 3]);
  const tokenPerSecond = new BigNumber(masterchefResults[masterchefResults.length - 2]);
  const tokenMaximumSupply = new BigNumber(masterchefResults[masterchefResults.length - 1]);
  const nftIds = [];

  const newFarms = farmInitialState.farms.map((farm, i) => {
    const erc20Index = i * erc20Length;
    const masterChefIndex = i * masterChefLength;

    const tokenBalanceLP = new BigNumber(erc20Results[erc20Index + 0]);
    const quoteTokenBalanceLP = new BigNumber(erc20Results[erc20Index + 1]);
    const tokenDecimals = erc20Results[erc20Index + 2][0];
    const quoteTokenDecimals = erc20Results[erc20Index + 3][0];
    const lpTokenBalanceMC = new BigNumber(erc20Results[erc20Index + 4]);
    const lpTotalSupply = new BigNumber(erc20Results[erc20Index + 5]);
    const userAllowance = isUserConnected ? new BigNumber(erc20Results[erc20Index + 6]) : ZERO;
    const userBalance =  isUserConnected ? new BigNumber(erc20Results[erc20Index + 7]) : ZERO;
    const allocPoint = new BigNumber(masterchefResults[masterChefIndex + 0][1]._hex);
    const harvestInterval = new BigNumber(masterchefResults[masterChefIndex + 0][5]._hex);
    const depositFeeBP = masterchefResults[masterChefIndex + 0].depositFeeBP;
    const totalAllocPoint = new BigNumber(masterchefResults[masterChefIndex + 1]);
    const stakedBalance = isUserConnected ? new BigNumber(masterchefResults[masterChefIndex + 2][0]._hex) : ZERO;
    const userNextHarvestUntil = isUserConnected ? new BigNumber(masterchefResults[masterChefIndex + 2][5]._hex) : ZERO;
    const userNftId = isUserConnected ? new BigNumber(masterchefResults[masterChefIndex + 2][6]._hex) : ZERO;
    const userHasNft = isUserConnected ? masterchefResults[masterChefIndex + 2][9] : false;
    const pendingToken = isUserConnected ? new BigNumber(masterchefResults[masterChefIndex + 3]) : ZERO;
    const userCanHarvest = isUserConnected ? masterchefResults[masterChefIndex + 4][0] : false;

    let tokenAmount;
    let lpTotalInQuoteToken;
    let tokenPriceVsQuote;

    if (farm.isTokenOnly) {
      tokenAmount = lpTokenBalanceMC.div(new BigNumber(10).pow(tokenDecimals));
      
      if (farm.token === process.env.REACT_APP_STABLE_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        tokenPriceVsQuote = new BigNumber(1);
      } else {
        tokenPriceVsQuote = quoteTokenBalanceLP.div(tokenBalanceLP);
      }
      lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote);
    } else {
      // Ratio in % a LP tokens that are in staking, vs the total number in circulation
      const lpTokenRatio = lpTokenBalanceMC.div(lpTotalSupply);

      // Total value in staking in quote token value
      lpTotalInQuoteToken = quoteTokenBalanceLP
        .div(new BigNumber(10).pow(tokenDecimals))
        .times(new BigNumber(2))
        .times(lpTokenRatio);

      // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
      tokenAmount = tokenBalanceLP.div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio);
      const quoteTokenAmount = quoteTokenBalanceLP
        .div(new BigNumber(10).pow(quoteTokenDecimals))
        .times(lpTokenRatio);

      if (tokenAmount.gt(0)) {
        tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount);
      } else {
        tokenPriceVsQuote = quoteTokenBalanceLP
          .div(new BigNumber(tokenBalanceLP))
          .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals));
      }
    }

    if (!farm.isTokenOnly) {
      if (farm.token === process.env.REACT_APP_NATIVE_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        if (tokenPriceVsQuote.isNaN()) {
          tokenPriceVsQuote = new BigNumber(process.env.REACT_APP_DEFAULT_PRICE);
          nativeTokenPriceDefault = true;
        }
        nativeTokenPrice = tokenPriceVsQuote;
      }

      if (farm.token === process.env.REACT_APP_NETWORK_TOKEN && farm.quoteToken === process.env.REACT_APP_STABLE_TOKEN) {
        networkTokenPrice = tokenPriceVsQuote;
      }
    }
    const poolWeight = allocPoint.div(totalAllocPoint);

    let pendingExperience = ZERO;
    if (userHasNft) {
      nftIds.push(userNftId);
      pendingExperience = (pendingToken.times(experienceRate)).div(10000);
    }

    return {
      ...farm,
      tokenAmount: tokenAmount.toJSON(),
      lpTotalInQuoteToken: lpTotalInQuoteToken
        .times(new BigNumber(10).pow(tokenDecimals - quoteTokenDecimals))
        .toJSON(),
      tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
      tokenDecimals,
      quoteTokenDecimals,
      userAllowance: userAllowance.toJSON(),
      userBalance: userBalance.toJSON(),
      allocPoint: allocPoint.toJSON(),
      harvestInterval: harvestInterval.toJSON(),
      depositFeeBP,
      totalAllocPoint: totalAllocPoint.toJSON(),
      tokenPerSecond: tokenPerSecond.toJSON(),
      stakedBalance: stakedBalance.toJSON(),
      userNextHarvestUntil: userNextHarvestUntil.toJSON(),
      userNftId: userNftId.toJSON(),
      userHasNft,
      pendingToken: pendingToken.toJSON(),
      pendingExperience: pendingExperience.toJSON(),
      userCanHarvest,
      poolWeight: poolWeight.toJSON(),
      isNative: farm.token === process.env.REACT_APP_NATIVE_TOKEN || farm.quoteToken === process.env.REACT_APP_NATIVE_TOKEN,
    };
  });

  let nfts = [];
  if (nftIds.length > 0) {
    nfts = await getNfts(nftIds);
  }

  const farmsWithApr = newFarms.map(farm => {
    const tokenRewardPerSecond = new BigNumber(farm.tokenPerSecond || 1)
        .times(farm.poolWeight)
        .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    const tokenRewardPerYear = tokenRewardPerSecond.times(process.env.REACT_APP_SECONDS_PER_YEAR);

    let apr;
    apr = nativeTokenPrice.times(tokenRewardPerYear);

    let total = new BigNumber(farm.lpTotalInQuoteToken || 0);
    if (farm.quoteToken === process.env.REACT_APP_NETWORK_TOKEN) {
      total = total.times(networkTokenPrice);
    }

    if (total.gt(0)) {
      apr = apr.div(total);
    }

    if (apr.gt(maxFarmApr)) {
      maxFarmApr = apr;
    }

    let totalValue = new BigNumber(farm.lpTotalInQuoteToken);
    if (totalValue.isNaN()) {
      totalValue = ZERO;
    }
    if (farm.quoteToken === process.env.REACT_APP_NETWORK_TOKEN) {
      totalValue = networkTokenPrice.times(totalValue);
    } else if (farm.quoteToken === process.env.REACT_APP_NATIVE_TOKEN) {
      totalValue = nativeTokenPrice.times(totalValue);
    }

    tvl = tvl.plus(totalValue);
    if ([farm.token, farm.quoteToken].includes(process.env.REACT_APP_NATIVE_TOKEN)) {
      tnl = tnl.plus(totalValue);
    }

    return {
      ...farm,
      apr: apr.toJSON(),
      totalValue: totalValue.toJSON(),
      selectedNft: nfts.find(card => farm.userHasNft && farm.userNftId === card.pid),
    };
  });

  return {
    nativeTokenPrice: nativeTokenPrice.toJSON(),
    nativeTokenPriceDefault,
    pendingUSD: pendingUSD.toJSON(),
    tnl: tnl.toJSON(),
    tvl: tvl.toJSON(),
    maxFarmApr: maxFarmApr.toJSON(),
    tokenTotalSupply: tokenTotalSupply.toJSON(),
    tokenTotalBurned: tokenTotalBurned.toJSON(),
    maxUserTransferAmount: new BigNumber(maxUserTransferAmount).toJSON(),
    maxUserTransferAmountRate: new BigNumber(maxUserTransferAmountRate).toNumber(),
    totalUSDCCollected: totalUSDCCollected.toJSON(),
    tokenPerSecond: tokenPerSecond.toJSON(),
    tokenMaximumSupply: tokenMaximumSupply.toJSON(),
    farms: farmsWithApr,
    firstLoad: false,
  }
};

export const approveFarm = async (token) => {
  const tokenContract = await getSignedContract(token, erc20Abi);
  return await tokenContract.approve(getAddress('masterChef'), ethers.constants.MaxUint256);
}

export const withdrawFarm = async (pid, amount) => {
  const masterChefContract = await getSignedContract('masterChef', masterChefAbi);
  return await masterChefContract.withdraw(pid, amount);
}

export const depositFarm = async (pid, amount) => {
  const masterChefContract = await getSignedContract('masterChef', masterChefAbi);
  return await masterChefContract.deposit(pid, amount);
}

export const harvestFarm = async (pid) => {
  return await depositFarm(pid, '0');
}

// NFT section
export const withdrawFarmNft = async (pid) => {
  const masterChefContract = await getSignedContract('masterChef', masterChefAbi);
  return await masterChefContract.withdrawNFT(pid);
}

export const depositFarmNft = async (pid, nftPid) => {
  const masterChefContract = await getSignedContract('masterChef', masterChefAbi);
  return await masterChefContract.addNFT(pid, nftPid);
}
