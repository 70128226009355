import React, { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';

import NftNav from './components/NftNav';
import NftCard from './components/NftCard';
import NftInfo from './components/NftInfo';

import { fetchNftAirdrop, claimNftAirdrop } from '../../blockchain/nft';

import { WalletContext } from '../../context/wallet';
import { ToastContext } from '../../context/toast';

const NftAirdropPage = () => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [started, setStarted] = useState(false);
  const [nftAirdropState, setNftAirdropState] = useState({
    startTime: 0,
    userCanClaim: false,
    firstLoad: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const syncNftAirdrop = async () => {
      const rs = await fetchNftAirdrop();
      setNftAirdropState(prevState => ({ ...prevState, ...rs, firstLoad: false }));
    }

    const intervalId = setInterval(syncNftAirdrop, 5000);

    syncNftAirdrop();

    return () => clearInterval(intervalId);
  }, [setNftAirdropState]);

  useEffect(() => {
    const updateTimes = () => {
      const currentTime = Date.now() / 1000;
      const ss = new BigNumber(nftAirdropState.startTime);

      setStarted(prevState => prevState || (ss.gt(0) && ss.lt(currentTime)));
    }

    const intervalId = setInterval(updateTimes, 1000);

    updateTimes();

    return () => clearInterval(intervalId);
  }, [nftAirdropState.startTime, setStarted]);

  const showConfetti = () => {
    confetti({
      resize: true,
      particleCount: 200,
      startVelocity: 30,
      gravity: 0.5,
      spread: 350,
      origin: {
        x: 0.5,
        y: 0.3,
      },
    });
  }

  const handleClaimNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await claimNftAirdrop();
      await tx.wait();
      addToast('3D NFT Airdrop claim succeeded', 'is-success');
      showConfetti();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx?.error !== undefined) {
      console.log('error', tx.error);
      addToast('3D NFT Airdrop claim failed', 'is-danger');
    }

    setPendingTx(false);
  }

  const renderApproveOrClaimButton = () => {
    if (walletAddress === null) {
      return (
        <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
          Unlock
        </button>
      );
    }

    return (
      <button
        disabled={ pendingTx || !nftAirdropState.userCanClaim || !started }
        type="button"
        className={ `button is-primary is-fullwidth ${pendingTx ? 'is-loading' : ''}` }
        onClick={ handleClaimNft }
      >
        CLAIM
      </button>
    );
  }

  return (
    <>
      <NftNav />
      <header className="hero parallax" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")' }}>
        <div className="hero-body">
          <div className="container">
            <div className="hero-box has-text-centered">
              <p className="title">3D NFT ArtWorks Airdrop</p>
              <p className="subtitle"><span className="has-text-primary">3D NFT's ArtWorks</span> power will be unique and generated randomly.</p>
            </div>
          </div>
        </div>
      </header>
      <main role="main" className="section">
        <div className="container">
          <div className="columns is-multiline is-justify-content-center">
            <div className="column is-half-desktop">
              <div className="card mb-5">
                <div className="card-content">
                  { renderApproveOrClaimButton() }
                </div>
                {nftAirdropState.firstLoad ? null : (
                  <div className="card-footer">
                    { !started ? (
                      <p className="card-footer-item">
                        <span className="mr-1">Countdown until Start</span> <Countdown date={ new BigNumber(nftAirdropState.startTime).times(1000).toNumber() } />
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
              {started && !nftAirdropState.userCanClaim ? (
                <div className="notification is-danger is-light mb-3">
                  * Your address in unreachable in the top 10 holders or your 3D NFT was already claimed.
                </div>
              ) : null}
              <NftInfo />
            </div>
            <div className="column is-half-desktop">
              <NftCard nftData={{
                pid: 2110,
                experience: 287,
                generation: 1,
                power: 27,
                boostStake: 12.18,
              }} />
            </div>
          </div>
        </div>
      </main>
      <div className="parallax is-hidden-mobile" style={{ backgroundImage: 'url("/images/parallax/bg-1.png")', height: '100vh' }} />
    </>
  );
}

export default NftAirdropPage;
